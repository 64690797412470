import { useState, useEffect } from 'react';
import Cache from '../Services/Cache';

interface UseFetchProps<T, D extends readonly unknown[]> {
    service: () => Promise<T>;
    cacheKey: string;
    dependencies?: D;
    transformData?: (data: T) => T;
}

export const useFetch = <T, D extends readonly unknown[]>({
    service,
    cacheKey,
    dependencies = [] as unknown as D,
    transformData,
}: UseFetchProps<T, D>) => {
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const cache = Cache.getInstance<T>(cacheKey);

    useEffect(() => {
        const fetchData = async () => {
            const cachedData = cache.get(cacheKey);

            if (cachedData) {
                setData(cachedData);
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            try {
                const result = await service();
                const processedData = transformData ? transformData(result) : result;
                cache.set(cacheKey, processedData);
                setData(processedData);
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [...dependencies, cacheKey]);

    return { data, isLoading, error };
};
