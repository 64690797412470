import { lazy } from 'react';

const errorHandling = (e: ErrorCallback) => {
    if (import.meta.env.DEV) {
        console.log(e);
    }
    throw new Error("Can't load module");
};

export const uiQuantityInput = () => lazy(async () => await import('Ui/QuantityInput').catch(errorHandling));
export const cartAddToCart = () => lazy(async () => await import('Cart/AddToCart').catch(errorHandling));
export const uiLoader = () => lazy(async () => await import('Ui/Loader').catch(errorHandling));
export const selectDropDown = () => lazy(async () => await import('Ui/Select').catch(errorHandling));
export const uiBreadcrumbs = () => lazy(async () => await import('Ui/Breadcrumbs').catch(errorHandling));

export const uiDeviceDetect = async () =>
    await import('Ui/DeviceDetect')
        .then(module => module.default)
        .catch(() => {
            return () => {
                return { isMobile: false };
            };
        });

export const uiEmrysThemeProvider = () => lazy(async () => await import('Ui/EmrysThemeProvider').catch(errorHandling));
