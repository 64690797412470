import { GET_ALL_PRODUCTS, GET_PRODUCT_BY_CODE, GET_PRODUCT_ATTRIBUTES, GET_VARIANTS_PRODUCT_BY_CODE } from '../queries';
import { ProductInterface } from '../interfaces/Product';
import { ProductAttributeInterface } from '../interfaces/ProductAttribute';
import { executeQuery } from '../Utils/helperFunctions';
import Cache from './Cache';

interface AllProductsCache {
    totalItems: number;
    products: ProductInterface[];
}

interface ProductCache {
    product: ProductInterface;
}

interface ProductVariantsCache {
    products: ProductInterface;
}

interface ProductAttributesCache {
    products: ProductAttributeInterface[];
}

class ProductsService {
    private allProductsCache: Cache<AllProductsCache>;
    private productCache: Cache<ProductCache>;
    private productVariantsCache: Cache<ProductVariantsCache>;
    private productAttributesCache: Cache<ProductAttributesCache>;

    constructor() {
        this.allProductsCache = Cache.getInstance<AllProductsCache>('allProductsCache');
        this.productCache = Cache.getInstance<ProductCache>('productCache');
        this.productVariantsCache = Cache.getInstance<ProductVariantsCache>('productVariantsCache');
        this.productAttributesCache = Cache.getInstance<ProductAttributesCache>('productAttributesCache');
    }

    async getAllProducts(
        codes: string[] | null | undefined,
        page: number = 1,
        itemsPerPage: number = 30,
    ): Promise<{
        totalItems: number;
        products: ProductInterface[];
    }> {
        const cacheKey = `allProducts_${codes ? codes.join('_') : 'all'}_${page}_${itemsPerPage}`;
        const cachedData = this.allProductsCache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        try {
            const { data } = await executeQuery<{ products: ProductInterface[] }>(GET_ALL_PRODUCTS(page, itemsPerPage, codes || []));
            const products = Array.isArray(data.products) ? data.products : [];

            const totalItems = products.length;
            const result = { totalItems, products };
            this.allProductsCache.set(cacheKey, result);
            return result;
        } catch (error) {
            console.error('Error fetching products:', error);
            throw error;
        }
    }

    async getProductByCode(codes: string[] | string | null | undefined, page: number = 1, itemsPerPage: number = 30): Promise<ProductInterface> {
        const cacheKey = `productByCode_${
            codes ?
                Array.isArray(codes) ?
                    codes.join('_')
                :   codes
            :   'all'
        }_${page}_${itemsPerPage}`;
        const cachedData = this.productCache.get(cacheKey);

        if (cachedData) {
            return cachedData.product;
        }

        try {
            const { data } = await executeQuery<{ product: ProductInterface }>(GET_PRODUCT_BY_CODE(page, itemsPerPage, codes || []));
            this.productCache.set(cacheKey, { product: data.product });
            return data.product;
        } catch (error) {
            console.error('Error fetching product by code:', error);
            throw error;
        }
    }

    async getVariantsProductByCode(codes: string[] | string): Promise<ProductInterface> {
        const cacheKey = `variantsProductByCode_${Array.isArray(codes) ? codes.join('_') : codes}`;
        const cachedData = this.productVariantsCache.get(cacheKey);

        if (cachedData) {
            return cachedData.products;
        }

        try {
            const { data } = await executeQuery<{ products: ProductInterface }>(GET_VARIANTS_PRODUCT_BY_CODE(codes));
            this.productVariantsCache.set(cacheKey, { products: data.products });
            return data.products;
        } catch (error) {
            console.error('Error fetching variants product by code:', error);
            throw error;
        }
    }

    async getProductAttributes(code: string): Promise<ProductAttributeInterface[]> {
        const cacheKey = `productAttributes_${code}`;
        const cachedData = this.productAttributesCache.get(cacheKey);

        if (cachedData) {
            return cachedData.products;
        }

        try {
            const { data } = await executeQuery<{ products: ProductAttributeInterface[] }>(GET_PRODUCT_ATTRIBUTES(code));
            this.productAttributesCache.set(cacheKey, { products: data.products });
            return data.products;
        } catch (error) {
            console.error('Error fetching product attributes:', error);
            throw error;
        }
    }
}

export default ProductsService;
