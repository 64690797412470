class Cache<T> {
    private static instances: Map<string, Cache<unknown>> = new Map();
    private cache: Record<string, T>;

    private constructor() {
        this.cache = {};
    }

    public static getInstance<U>(key: string): Cache<U> {
        if (!Cache.instances.has(key)) {
            Cache.instances.set(key, new Cache<U>());
        }
        return Cache.instances.get(key) as Cache<U>;
    }

    public get(key: string): T | undefined {
        return this.cache[key];
    }

    public set(key: string, value: T): void {
        this.cache[key] = value;
    }

    public clear(): void {
        this.cache = {};
    }
}

export default Cache;
