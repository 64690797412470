import ProductsService from './ProductsService';
import ProductVariantsService from './ProductVariantsService';
import CountriesService from './CountriesService';
import TaxonsService from './TaxonsService';
import AuthService from './AuthService';
import FilterService from './FilterService';
import OurPartnerBrandsService from './OurPartnerBrandsService';

export const productsService = ProductsService;
export const productVariantsService = ProductVariantsService;
export const countriesService = CountriesService;
export const taxonService = TaxonsService;
export const authService = AuthService;
export const ourPartnerBrandsService = OurPartnerBrandsService;
export const filterService = FilterService;
