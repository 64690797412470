import { GET_COUNTRY_BY_CODE } from '../queries';
import { CountryInterface } from '../interfaces/Country';
import { executeQuery } from '../Utils/helperFunctions'; // Adjust the import path as necessary

export default class CountriesService {
    async getCountryByCode(code: string): Promise<CountryInterface> {
        try {
            const { data } = await executeQuery<{ country: CountryInterface }>(GET_COUNTRY_BY_CODE(code));

            return data.country;
        } catch (error) {
            console.error('Error fetching country by code:', error);
            throw error;
        }
    }
}
